<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/cliente/socios')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Cadastrar sócio</span>
        </span>
        <!-- Funcionários -->
        <div class="row">
          <div class="col-lg-8">
            <div class="card p-4 mt-4 text-start">
              <div class="p-2">
                <div class="row">
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-cidade" class="form-label">CPF</label>
                    <input
                      type="text"
                      id="input-cpf"
                      class="form-control"
                      aria-describedby="input-cpf"
                      placeholder="Informe aqui o CPF"
                      v-model="model.cpf"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-nome" class="form-label"
                      >Nome completo</label
                    >
                    <input
                      type="text"
                      id="input-nome"
                      class="form-control"
                      aria-describedby="input-nome"
                      placeholder="Informe aqui o nome do contato"
                      v-model="model.fullname"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-text" class="form-label"
                      >Nome da mãe</label
                    >
                    <input
                      type="text"
                      id="input-mae"
                      class="form-control"
                      aria-describedby="input-mae"
                      placeholder="Informe aqui o nome da mãe"
                      v-model="model.mother_name"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-tel" class="form-label"
                      >Nome do pai</label
                    >
                    <input
                      type="text"
                      id="input-pai"
                      class="form-control"
                      aria-describedby="input-pai"
                      placeholder="Informe aqui o nome do pai"
                      v-model="model.father_name"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-tel" class="form-label"
                      >Data de nascimento</label
                    >
                    <input
                      type="date"
                      id="input-birth"
                      class="form-control"
                      aria-describedby="input-birth"
                      placeholder="Informe aqui a data de nascimento"
                      v-model="model.date_birth"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-nacionalidade" class="form-label"
                      >Cidade/Município de nascimento + UF</label
                    >
                    <input
                      type="text"
                      id="input-nascionalidade"
                      class="form-control"
                      aria-describedby="input-nascionalidade"
                      placeholder="Informe aqui a Cidade/Município de nascimento + UF"
                      v-model="model.birth_city"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-ethnicity" class="form-label"
                      >Etnia</label
                    >
                    <base-select
                      :options="etnias"
                      title="etnia"
                      :show_title="false"
                      @update-selected="model.ethnicity = $event"
                      aria-describedby="input-ethnicity"
                      id="input-ethnicity"
                      v-model="model.ethnicity"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-gender" class="form-label">Gênero</label>
                    <base-select
                      :options="genders"
                      title="gênero"
                      :show_title="false"
                      @update-selected="model.gender = $event"
                      aria-describedby="input-gender"
                      id="input-gender"
                      v-model="model.gender"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-profissao" class="form-label"
                      >Profissão</label
                    >
                    <input
                      type="text"
                      id="input-profissao"
                      class="form-control"
                      aria-describedby="input-profissao"
                      placeholder="Informe aqui a Profissão"
                      v-model="model.profission"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-2">
                    <label for="input-status_civil" class="form-label"
                      >Estado civil</label
                    >
                    <base-select
                      :options="status_civiles"
                      title="estado civil"
                      :show_title="false"
                      @update-selected="model.status_civil = $event"
                      aria-describedby="input-status_civil"
                      id="input-status_civil"
                      v-model="model.status_civil"
                    />
                  </div>
                  <div
                    class="col-lg-6 col-sm-12 text-primary mb-2"
                    v-show="model.status_civil === 2"
                  >
                    <label for="input-gender" class="form-label"
                      >Regime de bens</label
                    >
                    <base-select
                      :options="property_regimes"
                      title="regime de bens"
                      :show_title="false"
                      @update-selected="model.marital_property_systems = $event"
                      aria-describedby="input-marital_property_systems"
                      id="input-marital_property_systems"
                      v-model="model.marital_property_systems"
                    />
                  </div>

                  <hr class="my-4" />
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-rg" class="form-label"
                      >Registro Geral</label
                    >
                    <input
                      type="text"
                      id="input-rg"
                      class="form-control"
                      aria-describedby="input-rg"
                      placeholder="Informe aqui o registro geral"
                      v-model="model.rg_number"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-dt-oe" class="form-label"
                      >Data de expedição</label
                    >
                    <input
                      type="date"
                      id="input-birth"
                      class="form-control"
                      aria-describedby="input-birth"
                      placeholder="Informe aqui a data de nascimento"
                      v-model="model.rg_date"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-2">
                    <label for="input-oe" class="form-label"
                      >Órgão Expedidor</label
                    >
                    <input
                      type="text"
                      id="input-oe"
                      class="form-control"
                      aria-describedby="input-oe"
                      placeholder="Informe aqui o órgão expedidor"
                      v-model="model.rg_emitter"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-2">
                    <label for="input-oe" class="form-label">UF</label>
                    <input
                      type="text"
                      id="input-oe"
                      class="form-control"
                      aria-describedby="input-oe"
                      placeholder="Informe aqui o estado de emissão"
                      v-model="model.rg_uf"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card p-4 mt-4 text-start">
              <div class="p-2">
                <div class="row">
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-tel" class="form-label">Telefone</label>
                    <input
                      type="text"
                      id="input-tel"
                      class="form-control"
                      aria-describedby="input-tel"
                      placeholder="Informe aqui o telefone"
                      v-model="model.phone"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-2">
                    <label for="input-tel" class="form-label">Email</label>
                    <input
                      type="text"
                      id="input-email"
                      class="form-control"
                      aria-describedby="input-email"
                      placeholder="Informe aqui o email"
                      v-model="model.email"
                    />
                  </div>
                  <hr class="my-4" />
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-tel" class="form-label"
                      >Número do NIT/PIS</label
                    >
                    <input
                      type="text"
                      id="input-birth"
                      class="form-control"
                      aria-describedby="input-birth"
                      placeholder="Informe aqui o número do NIT ou PIS"
                      v-model="model.pis_number"
                    />
                  </div>
                  <hr class="my-4" />
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-tel" class="form-label"
                      >Título de eleitor</label
                    >
                    <input
                      type="text"
                      id="input-te"
                      class="form-control"
                      aria-describedby="input-te"
                      placeholder="Informe aqui o número do título de eleitor"
                      v-model="model.voter_number"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-tel" class="form-label">Zona</label>
                    <input
                      type="text"
                      id="input-te"
                      class="form-control"
                      aria-describedby="input-te"
                      placeholder="Informe aqui o número da zona"
                      v-model="model.voter_zone"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-2">
                    <label for="input-tel" class="form-label">Seção</label>
                    <input
                      type="text"
                      id="input-te"
                      class="form-control"
                      aria-describedby="input-te"
                      placeholder="Informe aqui o número da seção"
                      v-model="model.voter_section"
                    />
                  </div>
                  <hr class="my-4" />
                  <div class="col-lg-6 col-sm-12 text-primary">
                    <label for="input-irpf" class="form-label"
                      >Recibo do IRPF</label
                    >
                    <input
                      type="text"
                      id="input-irpf"
                      class="form-control"
                      aria-describedby="input-irpf"
                      :disabled="model.switch_irpf"
                      v-model="model.number_receita"
                      :placeholder="
                        !model.switch_irpf
                          ? 'Informe aqui o número do recibo da última entrega de IRPF'
                          : 'N/A'
                      "
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary">
                    <label for="switch-irpf" class="form-label"
                      >Nunca declarei</label
                    >
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="switch-irpf"
                        v-model="model.switch_irpf"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card p-4 mt-4 text-start">
              <div class="p-2">
                <h4 class="text-gray text-disabled font-weight-light">
                  Endereço residencial completo do sócio
                </h4>
                <div class="d-flex flex-row mb-3 align-items-center">
                  <div class="row me-2">
                    <label for="input-cep p-0" class="form-label"
                      >Qual o CEP?</label
                    >
                    <input
                      type="text"
                      id="input-cep"
                      class="form-control mx-3"
                      aria-describedby="input-cep"
                      placeholder="_ _ _ _ _ - _ _ _"
                      v-model="model.postal_code"
                    />
                  </div>
                  <div class="mt-4 ms-4">
                    <button
                      @click="getCep('model')"
                      class="btn font-weight-bold btn-primary text-white btn-shadow"
                    >
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-rua" class="form-label"
                      >Qual a rua?</label
                    >
                    <input
                      type="text"
                      id="input-rua"
                      class="form-control"
                      aria-describedby="input-rua"
                      placeholder="Informe aqui a rua"
                      v-model="model.street"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary">
                    <label for="input-bairro" class="form-label"
                      >Qual o bairro?</label
                    >
                    <input
                      type="text"
                      id="input-bairro"
                      class="form-control"
                      aria-describedby="input-bairro"
                      placeholder="Informe aqui o bairro"
                      v-model="model.neighborhood"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-cidade" class="form-label"
                      >Qual a cidade?</label
                    >
                    <input
                      type="text"
                      id="input-cidade"
                      class="form-control"
                      aria-describedby="input-cidade"
                      placeholder="Informe aqui a cidade"
                      v-model="model.city"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-estado" class="form-label"
                      >Qual o estado?</label
                    >
                    <input
                      type="text"
                      id="input-estado"
                      class="form-control"
                      aria-describedby="input-estado"
                      placeholder="Informe aqui o estado"
                      v-model="model.uf"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-nro" class="form-label"
                      >Qual o número?<span
                        v-tooltip="'Informe apenas o número, sem letras'"
                        ><i class="fas fa-info-circle ms-2"></i> </span
                    ></label>
                    <input
                      type="text"
                      id="input-nro"
                      class="form-control"
                      aria-describedby="input-nro"
                      placeholder="Informe aqui a nro"
                      v-model="model.number"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-complemento" class="form-label"
                      >Complemento</label
                    >
                    <input
                      type="text"
                      id="input-complemento"
                      class="form-control"
                      aria-describedby="input-complemento"
                      placeholder="Se houver, informe o complemento"
                      v-model="model.complement"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card mt-4 cursor-auto">
              <div class="d-flex justify-content-center">
                <span
                  class="mb-4 rounded-circle shadow mt-n5"
                  style="width: min-content; padding: 2rem"
                >
                  <i class="fa fa-id-card-clip fa-5x text-secondary"></i>
                </span>
              </div>
              <div class="row text-center">
                <div class="col-12 h4">
                  <b>{{ model.fullname }}</b>
                </div>
                <div class="col-12">
                  <b>CPF:</b> {{ model.cpf || "Não informado" }}
                </div>
                <div class="col-12">
                  <b>Email:</b>
                  {{ model.email || "Não informado" }}
                </div>
                <div class="col-12">
                  <b>Telefone:</b> {{ model.phone || "Não informado" }}
                </div>
                <div class="col-12">
                  <b>Cargo:</b>
                  {{ model.company_function || "Não informado" }}
                </div>
                <span class="d-flex justify-content-center">
                  <hr class="my-4" style="width: 90%" />
                </span>
                <div class="row text-center">
                  <span>
                    <button
                      @click="submmitForm()"
                      class="btn font-weight-bold btn-primary text-white btn-shadow"
                    >
                      <span class="mx-4">Cadastrar</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import BaseSelect from "@/components/BaseSelect.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "employees-form",
  components: {
    BaseSelect,
  },
  data() {
    return {
      model: {
        switch_irpf: false,
        name: "",
        fullname: "",
        mother_name: "",
        father_name: "",
        cpf: "",
        date_birth: "",
        rg_number: "",
        rg_emitter: "",
        rg_date: "",
        rg_uf: "",
        birth_city: "",
        ethnicity: "",
        gender: "",
        pis_number: "",
        marital_property_systems: "",
        status_civil: "",
        profission: "",
        phone: "",
        email: "",
        postal_code: "", // on table andresses
        street: "", // on table andresses
        neighborhood: "", // on table andresses
        city: "", // on table andresses
        uf: "", // on table andresses
        number: "", // on table andresses
        complement: "", // on table andresses,
        ctps_number: "",
        ctps_serie: "",
        ctps_uf: "",
        number_receita: "",
        voter_number: "",
        voter_section: "",
        voter_zone: "",
      },
      property_regimes: [
        "Comunhão Parcial de Bens",
        "Comunhão Universal de Bens",
        "Separação Legal de Bens",
        "Participação Final nos Aquestos",
      ],
      genders: ["Feminino", "Masculino", "Outro"],
      status_civiles: [
        "Solteiro(a)",
        "Casado(a)",
        "Viúvo(a)",
        "Separado(a)",
        "Divorciado(a)",
      ],
      etnias: [
        "Pardo(a)",
        "Branco(a)",
        "Negro(a)",
        "Indígena(a)",
        "Amarelo(a)",
        "Prefiro não informar",
      ],
      contracts: [
        "Não é contrato de expência",
        "30 dias",
        "45 dias + 45 dias",
        "60 dias",
        "90 dias",
        "30 dias + 60 dias",
        "60 dias + 30 dias",
        "90 dias",
      ],
      work_schedule: {
        times: [],
      },
      week_days: [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ],
    };
  },
  methods: {
    async submmitForm() {
      try {
        const formated = {
          ...this.model,
        };
        formated.marital_property_systems =
          this.property_regimes[this.model.marital_property_systems - 1];
        await api.post(`/company/partners`, formated);
        toast.success("Sócio cadastrado com sucesso");
        this.$router.push("/cliente/socios");
      } catch (e) {
        let message =
          "Ocorreu um erro ao cadastrar o sócio. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async getCep() {
      const { data } = await api.get(
        `https://viacep.com.br/ws/${this.model.postal_code}/json/`
      );
      this.model.street = data.logradouro;
      this.model.neighborhood = data.bairro;
      this.model.city = data.localidade;
      this.model.uf = data.uf;
      this.model.city_code = data.ibge;
    },
  },
  watch: {
    "model.switch_irpf": function () {
      if (this.model.switch_irpf) this.model.number_receita = "";
    },
    "model.status_civil": function () {
      this.model.marital_property_systems = "";
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
.rounded-circle {
  background-color: rgb(2, 0, 125);
}
</style>
