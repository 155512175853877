<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="handleGoBack()"
            class="btn fw-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Cadastrar Funcionário</span>
        </span>
        <!-- Funcionarios -->
        <!-- Step 1 -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <h3 class="text-primary text-start mb-3">Dados pessoais</h3>
            <!-- <h4 class="text-gray font-weight-light">Novo funcionário</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Nome completo*</label
                >
                <input
                  type="text"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="Informe aqui o nome completo"
                  v-model="employee.fullname"
                />
              </div>
            </div>
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Nome da mãe*</label
                >
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui o nome da mãe"
                  v-model="employee.mother_name"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Nome do pai*</label
                >
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui o nome do pai"
                  v-model="employee.father_name"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary">
                <label for="input-gender" class="form-label">Gênero</label>
                <base-select
                  :options="genders"
                  title="gênero"
                  :show_title="false"
                  @update-selected="employee.gender = $event"
                  aria-describedby="input-gender"
                  id="input-gender"
                  v-model="employee.gender"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary">
                <label for="input-marital_status" class="form-label"
                  >Estado civil</label
                >
                <base-select
                  :options="marital_statuses"
                  title="estado civil"
                  :show_title="false"
                  @update-selected="employee.marital_status = $event"
                  aria-describedby="input-marital_status"
                  id="input-marital_status"
                  v-model="employee.marital_status"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-type" class="form-label mb-2">CPF*</label>
                <input
                  type="text"
                  id="input-type"
                  class="form-control"
                  aria-describedby="input-type"
                  placeholder="000.000.000-00"
                  v-model="employee.cpf"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-agency" class="form-label mb-2"
                  >Data de nascimento*</label
                >
                <input
                  type="date"
                  id="input-agency"
                  class="form-control"
                  aria-describedby="input-agency"
                  v-model="employee.date_birth"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Step 2 -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <h3 class="text-primary text-start mb-3">Registro Geral</h3>
            <!-- <h4 class="text-gray font-weight-light">Novo funcionário</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Número*</label>
                <input
                  type="number"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="Informe aqui o nome completo"
                  v-model="employee.rg_number"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Órgão Expedidor*</label
                >
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui o órgão expedidor"
                  v-model="employee.rg_emitter"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Data de expedição*</label
                >
                <input
                  type="date"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui o nome do pai"
                  v-model="employee.rg_date"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-type" class="form-label mb-2">Estado*</label>
                <base-select
                  :options="ufs"
                  title="estado/uf"
                  :show_title="false"
                  @update-selected="employee.rg_uf = $event"
                  aria-describedby="input-contract"
                  id="input-contract"
                  v-model="employee.rg_uf"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Step 3 -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <h3 class="text-primary text-start mb-3">Título eleitoral</h3>
            <!-- <h4 class="text-gray font-weight-light">Novo funcionário</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Número*</label>
                <input
                  type="text"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="000000000000"
                  v-model="employee.voter_number"
                />
              </div>
              <div class="col-lg-3 col-sm-6 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Zona*</label>
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui a zona"
                  v-model="employee.voter_zona"
                />
              </div>
              <div class="col-lg-3 col-sm-6 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Seção*</label>
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui a seção"
                  v-model="employee.voter_section"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Step 4 -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <h3 class="text-primary text-start mb-3">Carteira de trabalho</h3>
            <!-- <h4 class="text-gray font-weight-light">Novo funcionário</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >PIS/PASEP*</label
                >
                <input
                  type="text"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="00.00000.00-0"
                  v-model="employee.pis_number"
                />
              </div>
            </div>
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Data de expedição*</label
                >
                <input
                  type="date"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  v-model="employee.ctps_date"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Número*</label>
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="0000000"
                  v-model="employee.ctps_number"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Série*</label>
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="000-0"
                  v-model="employee.ctps_serie"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-agency" class="form-label mb-2">UF*</label>
                <base-select
                  :options="ufs"
                  title="estado/uf"
                  :show_title="false"
                  @update-selected="employee.ctps_uf = $event"
                  aria-describedby="input-contract"
                  id="input-contract"
                  v-model="employee.ctps_uf"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Step 5 -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <h3 class="text-primary text-start mb-3">Contato pessoal</h3>
            <!-- <h4 class="text-gray font-weight-light">Novo funcionário</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Email*</label>
                <input
                  type="mail"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="Informe aqui o email"
                  v-model="employee.email"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Telefone (com DDD)*</label
                >
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="(00) 00000-0000"
                  v-model="employee.phone"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Step 6 -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <!-- <h4 class="text-gray font-weight-light">Novo funcionário</h4> -->
            <h3 class="text-primary text-start mb-3">Empresa</h3>
            <div class="row text-start">
              <div class="col-lg-12 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Cargo*</label>
                <input
                  type="text"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="Informe aqui o cargo"
                  v-model="employee.company_function"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Salário*</label>
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui o salário"
                  v-model="employee.salary"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary">
                <label for="input-contract" class="form-label"
                  >Período do contrato de experiência</label
                >
                <base-select
                  :options="contracts"
                  title="período do contrato"
                  :show_title="false"
                  @update-selected="employee.contract = $event"
                  aria-describedby="input-contract"
                  id="input-contract"
                  v-model="employee.contract"
                />
              </div>
              <hr />
              <h3 class="text-primary text-start mb-3">Horário de trabalho</h3>
              <h5 class="text-primary text-start mb-3">
                Horas trabalhadas ao total: {{ hours }} horas
                {{ minutes ? `e ${minutes} minutos` : "" }}
              </h5>
              <div class="row text-center text-primary h5">
                <div class="col-lg-6 d-none d-lg-block d-xl-none">Turno 1</div>
                <div class="col-lg-6 d-none d-lg-block d-xl-none">Turno 2</div>
              </div>
              <div v-for="(day, index) in work_schedule" :key="index">
                <div
                  class="row d-flex align-items-center p-4"
                  :class="day.day.toLowerCase()"
                >
                  <div class="col-lg-1 d-none d-lg-block">
                    <div class="row">
                      <div class="form-check">
                        <input
                          class="form-check-input mt-2"
                          type="checkbox"
                          :value="day.checked"
                          :id="`checked_${index}`"
                          v-model="day.checked"
                        />
                        <label
                          class="form-check-label fw-semibold text-primary"
                          :for="`checked_${index}`"
                        >
                          {{ day.day }}
                        </label>
                      </div>
                    </div>
                    <div class="row mb-4">
                      {{ day.hours ? `${day.hours}h` : ""
                      }}{{ day.minutes ? `${day.minutes}min` : "" }}
                    </div>
                  </div>
                  <div class="col-lg-1 d-lg-none ps-4 mb-2">
                    <div class="row">
                      <div class="form-check">
                        <input
                          class="form-check-input mt-2"
                          type="checkbox"
                          :value="day.checked"
                          :id="`checked_${index}`"
                          v-model="day.checked"
                        />
                        <label
                          class="form-check-label fw-semibold text-primary"
                          :for="`checked_${index}`"
                        >
                          {{ day.day }}
                        </label>
                        <span>
                          ~ {{ day.hours ? `${day.hours}h` : ""
                          }}{{ day.minutes ? `${day.minutes}min` : "" }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="row">
                      <span class="text-primary h5 d-lg-none d-xl-block"
                        >Turno 1</span
                      >
                      <day-schedule-input
                        :day="day"
                        :time="day.times[0]"
                        :required="true"
                        @update-start-hour="day.times[0].start_hour = $event"
                        @update-end-hour="day.times[0].end_hour = $event"
                        @update-minutes="handleHours(day)"
                      />
                    </div>
                  </div>
                  <div class="col-lg-1 text-center">
                    <label
                      for="input-cod"
                      class="form-label fw-bold text-primary"
                      >Intervalo</label
                    >
                  </div>
                  <div class="col-lg-5">
                    <div class="row">
                      <span class="text-primary h5 d-lg-none d-xl-block"
                        >Turno 2</span
                      >
                      <day-schedule-input
                        :day="day"
                        :time="day.times[1]"
                        :required="false"
                        @update-start-hour="day.times[1].start_hour = $event"
                        @update-end-hour="day.times[1].end_hour = $event"
                        @update-minutes="handleHours(day)"
                      />
                    </div>
                  </div>
                  <!-- <div
                    class="col-lg-4 mb-4"
                    v-if="work_schedule.times.length > 1"
                  >
                    <button
                      @click="handleWorkSchedule(0, index)"
                      class="btn-muted btn btn-danger text-white btn-shadow me-2 mt-4"
                    >
                      <span class="small">
                        <i class="fas fa-trash me-2"></i>Remover</span
                      >
                    </button>
                  </div> -->
                </div>
              </div>
              <p class="text-primary my-3 text-start fs-5">
                Horas totais:
                <b
                  >{{ hours }} horas
                  {{ minutes ? `e ${minutes} minutos` : "" }}</b
                >
              </p>
            </div>
          </div>
        </div>
        <!-- Step 7 -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <h3 class="text-primary text-start">Endereço</h3>
            <div class="d-flex flex-row mb-3 align-items-start">
              <div class="row mt-4 text-start">
                <label for="input-cep p-0" class="form-label"
                  >Qual o CEP?</label
                >
                <input
                  type="text"
                  id="input-cep"
                  class="form-control mb-3 ms-3"
                  aria-describedby="input-cep"
                  placeholder="_ _ _ _ _ - _ _ _"
                  v-model="employee.postal_code"
                />
              </div>
              <div class="ps-4 pt-2 mt-4 ms-4">
                <button
                  @click="getCep()"
                  class="btn font-weight-bold btn-primary text-white btn-shadow mt-4"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>
            </div>
            <div class="row text-start">
              <hr class="my-4 text-gray" />
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-rua" class="form-label">Qual a rua?</label>
                <input
                  type="text"
                  id="input-rua"
                  class="form-control"
                  aria-describedby="input-rua"
                  placeholder="Informe aqui a rua"
                  v-model="employee.street"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary">
                <label for="input-bairro" class="form-label"
                  >Qual o bairro?</label
                >
                <input
                  type="text"
                  id="input-bairro"
                  class="form-control"
                  aria-describedby="input-bairro"
                  placeholder="Informe aqui o bairro"
                  v-model="employee.neighborhood"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cidade" class="form-label"
                  >Qual a cidade?</label
                >
                <input
                  type="text"
                  id="input-cidade"
                  class="form-control"
                  aria-describedby="input-cidade"
                  placeholder="Informe aqui a cidade"
                  v-model="employee.city"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-estado" class="form-label"
                  >Qual o estado?</label
                >
                <base-select
                  :options="ufs"
                  title="estado/uf"
                  :show_title="false"
                  @update-selected="employee.uf = $event"
                  aria-describedby="input-contract"
                  id="input-contract"
                  v-model="employee.uf"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-nro" class="form-label"
                  >Qual o número?<span
                    v-tooltip="'Informe apenas o número, sem letras'"
                    ><i class="fas fa-info-circle ms-2"></i> </span
                ></label>
                <input
                  type="text"
                  id="input-nro"
                  class="form-control"
                  aria-describedby="input-nro"
                  placeholder="Informe aqui a nro"
                  v-model="employee.number"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-complemento" class="form-label"
                  >Complemento</label
                >
                <input
                  type="text"
                  id="input-complemento"
                  class="form-control"
                  aria-describedby="input-complemento"
                  placeholder="Se houver, informe o complemento"
                  v-model="employee.complement"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cadastro-imobiliario" class="form-label"
                  >Código do IPTU</label
                >
                <input
                  type="text"
                  id="input-cadastro-imobiliario"
                  class="form-control"
                  aria-describedby="input-cadastro-imobiliario"
                  placeholder="Informe aqui o cadastro imobiliário"
                  v-model="employee.iptu"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-metragem" class="form-label">Metragem</label>
                <input
                  type="text"
                  id="input-metragem"
                  class="form-control"
                  aria-describedby="input-metragem"
                  placeholder="Informe aqui a metragem"
                  v-model="employee.area_building"
                />
              </div>
            </div>
          </div>
        </div>
        <span class="text-center mt-4">
          <button
            @click="handleStep(1)"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Cadastrar</span>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
import BaseSelect from "@/components/BaseSelect.vue";
import DayScheduleInput from "@/components/DayScheduleInput.vue";
const toast = useToast();
export default {
  name: "employees-form",
  components: {
    BaseSelect,
    DayScheduleInput,
  },
  data() {
    return {
      actual_step: 0,
      month: "",
      year: "",
      employee: {},
      mounths: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      genders: ["Feminino", "Masculino", "Outro"],
      marital_statuses: [
        "Solteiro(a)",
        "Casado(a)",
        "Viúvo(a)",
        "Separado(a)",
        "Divorciado(a)",
      ],
      contracts: [
        "Não é contrato de expência",
        "30 dias",
        "45 dias + 45 dias",
        "60 dias",
        "90 dias",
        "30 dias + 60 dias",
        "60 dias + 30 dias",
        "90 dias",
      ],
      work_schedule: "",
      week_days: ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"],
      model: {
        mother_name: "",
        name: "",
        father_name: "",
        rg_number: "",
        rg_emitter: "",
        rg_date: "",
        rg_uf: "",
        cpf: "",
        marital_status: "",
        gender: "",
        birth_date: "",
        voter_section: "",
        voter_zone: "",
        voter_number: "",
        ctps_number: "",
        ctps_serie: "",
        ctps_uf: "",
        pis_number: "",
        email: "",
        phone: "",
        postal_code: "",
        city: "",
        uf: "",
        neighborhood: "",
        complement: "",
        number: "",
        company_function: "",
        salary: "",
      },
      steps: {
        0: "Parte 1",
        15: "Parte 2",
        30: "Parte 3",
        45: "Parte 4",
        60: "Parte 5",
        75: "Parte 6",
        90: "Parte 7",
      },
      hours: 0,
      minutes: 0,
      ufs: [],
    };
  },
  async mounted() {
    //this.handleWorkSchedule(1);
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
    await this.loadUFs();
    await this.loadEmployee();
    this.handleSchedule();
  },
  methods: {
    handleGoBack() {
      const previousUrl = localStorage.getItem("previousUrl");
      if (previousUrl) {
        this.$router.push(previousUrl);
      } else {
        this.$router.push("/");
      }
    },
    async submmitForm() {
      try {
        const { data } = await api.put(
          `/user-accountant/employees/${this.$route.params.id}`,
          this.employee
        );
        this.employee = data;
        toast.success("Funcionário atualizado com sucesso");
        this.$router.push("funcionarios");
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar o funcionário. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadEmployee() {
      try {
        const { data } = await api.get(
          `/user-accountant/employees/${this.$route.params.id}/getItem`
        );
        this.employee = data;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do funcionário. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    handleHours(day) {
      let minutes = 0;
      for (let time of day.times) {
        if (time.start_hour && time.end_hour) {
          const s_hour = parseInt(time.start_hour.split(":")[0]);
          const e_hour = parseInt(time.end_hour.split(":")[0]);
          const s_min = parseInt(time.start_hour.split(":")[1]);
          const e_min = parseInt(time.end_hour.split(":")[1]);
          let total_h = 0;
          let total_min = 0;

          if (s_hour > e_hour && e_min > s_min) {
            total_min = 60 - s_min + e_min;
            total_h = 24 - s_hour + e_hour - 1 + Math.trunc(total_min / 60);
            total_min = ((total_min % 60) / 60) * 60;
          } else if (s_hour > e_hour && e_min < s_min) {
            total_min = 60 - s_min + e_min;
            total_h = 24 - s_hour + e_hour - 1 + Math.trunc(total_min / 60);
            total_min = ((total_min % 60) / 60) * 60;
          } else if (s_hour > e_hour && e_min === s_min) {
            total_min = 0;
            total_h = 24 - s_hour + e_hour;
          } else if (s_hour === e_hour && e_min < s_min) {
            total_min = 60 - s_min + e_min;
            total_h = 24 - s_hour + e_hour - 1 + Math.trunc(total_min / 60);
            total_min = ((total_min % 60) / 60) * 60;
          } else if (s_hour === e_hour && e_min > s_min) {
            total_h = 0;
            total_min = e_min - s_min;
          } else if (s_hour === e_hour && e_min === s_min) {
            total_h = 0;
            total_min = 0;
          } else if (s_hour < e_hour && e_min < s_min) {
            total_h = e_hour - s_hour - 1;
            total_min = 60 - s_min + e_min;
          } else if (s_hour < e_hour && e_min > s_min) {
            total_h = e_hour - s_hour;
            total_min = e_min - s_min;
          } else if (s_hour < e_hour && e_min === s_min) {
            total_h = e_hour - s_hour;
            total_min = 0;
          }
          minutes += total_h * 60 + total_min;
        }
      }
      day.hours = Math.trunc(minutes / 60);
      day.minutes = ((minutes % 60) / 60) * 60;
      this.calculateHoursNMinutes();
    },
    handleSchedule() {
      const time_model = {
        start_hour: "",
        end_hour: "",
      };
      let week = [];
      this.week_days.forEach((day, index) => {
        week.push({
          day: day,
          checked: false,
          times: [],
          hours: 0,
          minutes: 0,
        });
        week[index].times.push(Object.assign({}, time_model));
        week[index].times.push(Object.assign({}, time_model));
      });
      this.work_schedule = week;
    },
    calculateHoursNMinutes() {
      let hours = 0;
      let minutes = 0;
      for (let day of this.work_schedule) {
        hours += day.hours;
        minutes += day.minutes;
      }
      this.hours = hours + Math.trunc(minutes / 60);
      this.minutes = ((minutes % 60) / 60) * 60;
    },
    updateMonth(e) {
      this.month = e;
    },
    updateYear(e) {
      this.year = e;
    },
    getUrl() {
      let url = "";
      const month = this.month + 1;
      if (month && this.year) url = `?month=${month}&year=${this.year}`;
      else if (month) url = `/?month=${month}`;
      else if (this.year) url = `/?year=${this.year}`;
      return url;
    },
    async getCep() {
      const { data } = await api.get(
        `https://viacep.com.br/ws/$this.employee.postal_code}/json/`
      );
      this.employee.street = data.logradouro;
      this.employee.neighborhood = data.bairro;
      this.employee.city = data.localidade;
      this.employee.uf = data.uf;
      this.employee.city_code = data.ibge;
    },
    /* handleWorkedHours() {
      let minutes = 0;
      for (let time of this.work_schedule.times) {
        const s_hour = time.start.split(':')[0];
        const s_min = time.start.split(':')[1];
        const e_hour = time.end.split(':')[0];
        const e_min = time.end.split(':')[1];

        const total_h = e_hour - s_hour;
        const total_m = e_min - s_min;
        minutes += (total_h*60) + total_m;
      }
      this.hours = Math.trunc(minutes/60)
      this.minutes = ((minutes%60)/60)*60
    }, */
    async loadUFs() {
      try {
        const { data } = await api.get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome`
        );
        this.ufs = data.map((uf) => {
          return uf.nome;
        });
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos estados. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
};
</script>
<style scoped>
.dom {
  border-radius: 20px 20px 0px 0px;
}
.sab {
  border-radius: 0px 0px 20px 20px;
}
.dom,
.ter,
.qui,
.sab {
  background-color: rgba(147, 147, 147, 0.059);
}
.seg,
.qua,
.sex {
  background-color: rgba(227, 227, 227, 0.059);
}
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}

.progress {
  border-radius: 20px;
}
</style>
