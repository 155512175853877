<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/cliente/funcionarios')"
            class="btn fw-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Cadastrar funcionário</span>
        </span>
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <!-- Funcionarios -->
        <!-- Step 1 -->
        <div class="card p-4 mt-4" v-if="actual_step === 0">
          <div
            class="progress mb-4"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="progress-bar"
              :style="'width: ' + actual_step + '%'"
            ></div>
          </div>
          <span class="text-primary h4 d-flex align-items-center">
            <span>
              <span class="text-primary h4"
                >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
                <span class="small">- *Campos obrigatórios</span></span
              >
            </span>
          </span>
          <div class="p-2">
            <h3 class="text-primary text-start mb-3">Dados pessoais</h3>
            <!-- <h4 class="text-gray font-weight-light">Novo funcionário</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Nome completo*</label
                >
                <input
                  type="text"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="Informe aqui o nome completo"
                  v-model="model.fullname"
                />
              </div>
            </div>
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Nome da mãe*</label
                >
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui o nome da mãe"
                  v-model="model.mother_name"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Nome do pai</label
                >
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui o nome do pai"
                  v-model="model.father_name"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary">
                <label for="input-gender" class="form-label">Gênero</label>
                <base-select
                  :options="genders"
                  title="gênero"
                  :show_title="false"
                  @update-selected="model.gender = $event"
                  :selected_id="model.gender"
                  aria-describedby="input-gender"
                  id="input-gender"
                  v-model="model.gender"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary">
                <label for="input-marital_status" class="form-label"
                  >Estado civil</label
                >
                <base-select
                  :options="status_civiles"
                  title="estado civil"
                  :show_title="false"
                  @update-selected="model.status_civil = $event"
                  :selected_id="model.status_civil"
                  aria-describedby="input-marital_status"
                  id="input-marital_status"
                  v-model="model.status_civil"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-type" class="form-label mb-2">CPF*</label>
                <input
                  type="text"
                  id="input-type"
                  class="form-control"
                  aria-describedby="input-type"
                  placeholder="000.000.000-00"
                  v-model="model.cpf"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-agency" class="form-label mb-2"
                  >Data de nascimento*</label
                >
                <input
                  type="date"
                  id="input-agency"
                  class="form-control"
                  aria-describedby="input-agency"
                  v-model="model.date_birth"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-2">
                <label for="input-ethnicity" class="form-label">Etnia</label>
                <base-select
                  :options="etnias"
                  title="etnia"
                  :show_title="false"
                  @update-selected="model.ethnicity = $event"
                  :selected_id="model.ethnicity"
                  aria-describedby="input-ethnicity"
                  id="input-ethnicity"
                  v-model="model.ethnicity"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-2">
                <label for="input-gender" class="form-label"
                  >Escolaridade</label
                >
                <base-select
                  :options="scholarships"
                  title="escolaridade"
                  :show_title="false"
                  @update-selected="model.schooling = $event"
                  :selected_id="model.schooling"
                  aria-describedby="input-scholarship"
                  id="input-gender"
                  v-model="model.schooling"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-city_birth" class="form-label"
                  >Cidade/Município de nascimento + UF</label
                >
                <input
                  type="text"
                  id="input-city_birth"
                  class="form-control"
                  aria-describedby="input-city_birth"
                  placeholder="Informe aqui a a cidade de nascimento"
                  v-model="model.city_birth"
                />
              </div>
            </div>
            <span class="text-center mt-4">
              <button
                @click="handleStep(1)"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Continuar</span>
              </button>
            </span>
          </div>
        </div>
        <!-- Step 2 -->
        <div class="card p-4 mt-4" v-if="actual_step === 17">
          <div
            class="progress mb-4"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="progress-bar"
              :style="'width: ' + actual_step + '%'"
            ></div>
          </div>
          <span class="text-primary h4 d-flex align-items-center">
            <span v-tooltip="'Voltar ao passo anterior'">
              <button
                @click="handleStep(0)"
                class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
              >
                <i class="fa fa-arrow-left"></i>
              </button>
            </span>
            <span>
              <span class="text-primary h4"
                >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
                <span class="small">- *Campos obrigatórios</span></span
              >
            </span>
          </span>
          <br />
          <div class="p-2">
            <h3 class="text-primary text-start mb-3">Registro Geral</h3>
            <!-- <h4 class="text-gray font-weight-light">Novo funcionário</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Número*</label>
                <input
                  type="text"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="Informe aqui o número do RG"
                  v-model="model.rg_number"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Órgão Expedidor*</label
                >
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui o órgão expedidor"
                  v-model="model.rg_emitter"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Data de expedição</label
                >
                <input
                  type="date"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui a data de expedição"
                  v-model="model.rg_date"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-type" class="form-label mb-2">Estado*</label>
                <base-select
                  :options="ufs"
                  title="estado/uf"
                  :show_title="false"
                  @update-selected="model.rg_uf = $event"
                  :selected_id="model.rg_uf"
                  aria-describedby="input-contract"
                  id="input-contract"
                  v-model="model.rg_uf"
                />
              </div>
            </div>
            <span class="text-center mt-4">
              <button
                @click="handleStep(1)"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Continuar</span>
              </button>
            </span>
          </div>
        </div>
        <!-- Step 3 -->
        <!--    
        <div class="card p-4 mt-4" v-if="actual_step === 30">
          <div
            class="progress mb-4"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="progress-bar"
              :style="'width: ' + actual_step + '%'"
            ></div>
          </div>
          <span class="text-primary h4 d-flex align-items-center">
            <span v-tooltip="'Voltar ao passo anterior'">
              <button
                @click="handleStep(0)"
                class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
              >
                <i class="fa fa-arrow-left"></i>
              </button>
            </span>
            <span>
              <span class="text-primary h4"
                >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
                <span class="small">- *Campos obrigatórios</span></span
              >
            </span>
          </span>
          <br />
          <div class="p-2">
            <h3 class="text-primary text-start mb-3">Título eleitoral</h3>
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Número*</label>
                <input
                  type="text"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="000000000000"
                  v-model="model.voter_number"
                />
              </div>
              <div class="col-lg-3 col-sm-6 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Zona*</label>
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui a zona"
                  v-model="model.voter_zona"
                />
              </div>
              <div class="col-lg-3 col-sm-6 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Seção*</label>
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui a seção"
                  v-model="model.voter_section"
                />
              </div>
            </div>
            <span class="text-center mt-4">
              <button
                @click="handleStep(1)"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Continuar</span>
              </button>
            </span>
          </div>
        </div> 
-->
        <!-- Step 4 -->
        <div class="card p-4 mt-4" v-if="actual_step === 34">
          <div
            class="progress mb-4"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="progress-bar"
              :style="'width: ' + actual_step + '%'"
            ></div>
          </div>
          <span class="text-primary h4 d-flex align-items-center">
            <span v-tooltip="'Voltar ao passo anterior'">
              <button
                @click="handleStep(0)"
                class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
              >
                <i class="fa fa-arrow-left"></i>
              </button>
            </span>
            <span>
              <span class="text-primary h4"
                >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
                <span class="small">- *Campos obrigatórios</span></span
              >
            </span>
          </span>
          <br />
          <div class="p-2">
            <h3 class="text-primary text-start mb-3">Carteira de trabalho</h3>
            <!-- <h4 class="text-gray font-weight-light">Novo funcionário</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Sobre a carteira de trabalho*</label
                >
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="ctps_fisica"
                    v-model="ctps_type"
                    :checked="ctps_type === 0"
                    :value="0"
                  />
                  <label class="form-check-label" for="ctps_fisica">
                    Possui carteira de trabalho física
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="ctps_digital"
                    v-model="ctps_type"
                    :checked="ctps_type === 1"
                    :value="1"
                  />
                  <label class="form-check-label" for="ctps_digital">
                    Possui carteira de trabalho digital
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="no_ctps"
                    v-model="ctps_type"
                    :checked="ctps_type === 2"
                    :value="2"
                  />
                  <label class="form-check-label" for="no_ctps">
                    Não possui carteira de trabalho
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div
                class="row text-start"
                v-if="ctps_type === 0 || ctps_type === 1"
              >
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-cod" class="form-label mb-2"
                    >PIS/PASEP*
                    <span v-tooltip="'Importante e obrigatório o preenchimento'"
                      ><i
                        class="fa fa-exclamation-triangle text-warning me-2"
                      ></i></span
                  ></label>
                  <input
                    type="text"
                    id="input-cod"
                    class="form-control"
                    aria-describedby="input-cod"
                    placeholder="00.00000.00-0"
                    v-model="model.pis_number"
                  />
                </div>
              </div>
              <div class="row text-start" v-if="ctps_type === 0">
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-cod" class="form-label mb-2"
                    >Data de expedição*</label
                  >
                  <input
                    type="date"
                    id="input-name"
                    class="form-control"
                    aria-describedby="input-name"
                    v-model="model.ctps_date"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-cod" class="form-label mb-2">Número*</label>
                  <input
                    type="text"
                    id="input-name"
                    class="form-control"
                    aria-describedby="input-name"
                    placeholder="0000000"
                    v-model="model.ctps_number"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-cod" class="form-label mb-2">Série*</label>
                  <input
                    type="text"
                    id="input-name"
                    class="form-control"
                    aria-describedby="input-name"
                    placeholder="000-0"
                    v-model="model.ctps_serie"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-agency" class="form-label mb-2">UF*</label>
                  <base-select
                    :options="ufs"
                    title="estado/uf"
                    :show_title="false"
                    @update-selected="model.ctps_uf = $event"
                    :selected_id="model.ctps_uf"
                    aria-describedby="input-contract"
                    id="input-contract"
                    v-model="model.ctps_uf"
                  />
                </div>
              </div>
            </div>
            <span class="text-center mt-4">
              <button
                @click="handleStep(1)"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Continuar</span>
              </button>
            </span>
          </div>
        </div>
        <!-- Step 5 -->
        <div class="card p-4 mt-4" v-if="actual_step === 51">
          <div
            class="progress mb-4"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="progress-bar"
              :style="'width: ' + actual_step + '%'"
            ></div>
          </div>
          <span class="text-primary h4 d-flex align-items-center">
            <span v-tooltip="'Voltar ao passo anterior'">
              <button
                @click="handleStep(0)"
                class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
              >
                <i class="fa fa-arrow-left"></i>
              </button>
            </span>
            <span>
              <span class="text-primary h4"
                >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
                <span class="small">- *Campos obrigatórios</span></span
              >
            </span>
          </span>
          <br />
          <div class="p-2">
            <h3 class="text-primary text-start mb-3">Contato pessoal</h3>
            <!-- <h4 class="text-gray font-weight-light">Novo funcionário</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Email*</label>
                <input
                  type="mail"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="Informe aqui o email"
                  v-model="model.email"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Telefone (com DDD)*</label
                >
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="(00) 00000-0000"
                  v-model="model.phone"
                />
              </div>
            </div>
            <span class="text-center mt-4">
              <button
                @click="handleStep(1)"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Continuar</span>
              </button>
            </span>
          </div>
        </div>
        <!-- Step 6 -->
        <div class="card p-4 mt-4" v-if="actual_step === 68">
          <div
            class="progress mb-4"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="progress-bar"
              :style="'width: ' + actual_step + '%'"
            ></div>
          </div>
          <span class="text-primary h4 d-flex align-items-center">
            <span v-tooltip="'Voltar ao passo anterior'">
              <button
                @click="handleStep(0)"
                class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
              >
                <i class="fa fa-arrow-left"></i>
              </button>
            </span>
            <span>
              <span class="text-primary h4"
                >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
                <span class="small">- *Campos obrigatórios</span></span
              >
            </span>
          </span>
          <br />
          <div class="p-2">
            <h3 class="text-primary text-start mb-3">Empresa</h3>
            <div class="row text-start">
              <div class="col-lg-12 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Descrição do cargo*</label
                >
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Descreva aqui o cargo"
                    id="cargo-textarea"
                    aria-describedby="cargo-textarea"
                    style="height: 100px"
                    v-model="model.company_function"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-salary" class="form-label mb-2"
                  >Salário*
                  <span
                    v-tooltip="
                      'Esta informação será analisada pelo departamento responsável'
                    "
                    ><i class="fas fa-info-circle"></i> </span
                ></label>
                <input
                  type="text"
                  id="input-salary"
                  class="form-control"
                  aria-describedby="input-salary"
                  placeholder="Digite o valor"
                  v-model="formatedAmount"
                  @input="formatPrice"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary">
                <label for="input-contract" class="form-label"
                  >Período do contrato de experiência</label
                >
                <base-select
                  :options="contracts"
                  title="período do contrato"
                  :show_title="false"
                  @update-selected="model.contract_experience_period = $event"
                  :selected_id="model.contract_experience_period"
                  aria-describedby="input-contract"
                  id="input-contract"
                  v-model="model.contract_experience_period"
                />
              </div>
              <hr />
              <h3 class="text-primary text-start mb-3">
                Horário de trabalho semanal
              </h3>
              <h5 class="text-primary text-start mb-3">
                Horas trabalhadas ao total: {{ hours }} horas
                {{ minutes ? `e ${minutes} minutos` : "" }}
              </h5>
              <div class="row text-center text-primary h5">
                <div class="col-lg-6 d-none d-lg-block d-xl-none">Turno 1</div>
                <div class="col-lg-6 d-none d-lg-block d-xl-none">Turno 2</div>
              </div>
              <div v-for="(day, index) in work_schedule" :key="index">
                <div
                  class="row d-flex align-items-center p-4"
                  :class="day.day.toLowerCase()"
                >
                  <div class="col-lg-1 d-none d-lg-block">
                    <div class="row">
                      <div class="form-check">
                        <input
                          class="form-check-input mt-2"
                          type="checkbox"
                          :value="day.checked"
                          :id="`checked_${index}`"
                          v-model="day.checked"
                        />
                        <label
                          class="form-check-label fw-semibold text-primary"
                          :for="`checked_${index}`"
                        >
                          {{ day.day }}
                        </label>
                      </div>
                    </div>
                    <div class="row mb-4">
                      {{ day.hours ? `${day.hours}h` : ""
                      }}{{ day.minutes ? `${day.minutes}min` : "" }}
                    </div>
                  </div>
                  <div class="col-lg-1 d-lg-none ps-4 mb-2">
                    <div class="row">
                      <div class="form-check">
                        <input
                          class="form-check-input mt-2"
                          type="checkbox"
                          :value="day.checked"
                          :id="`checked_${index}`"
                          v-model="day.checked"
                        />
                        <label
                          class="form-check-label fw-semibold text-primary"
                          :for="`checked_${index}`"
                        >
                          {{ day.day }}
                        </label>
                        <span>
                          ~ {{ day.hours ? `${day.hours}h` : ""
                          }}{{ day.minutes ? `${day.minutes}min` : "" }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="row">
                      <span class="text-primary h5 d-lg-none d-xl-block"
                        >Turno 1</span
                      >
                      <day-schedule-input
                        :day="day"
                        :time="day.times[0]"
                        :required="true"
                        @update-start-hour="day.times[0].start_hour = $event"
                        @update-end-hour="day.times[0].end_hour = $event"
                        @update-minutes="handleHours(day)"
                      />
                    </div>
                  </div>
                  <div class="col-lg-1 text-center">
                    <label
                      for="input-cod"
                      class="form-label fw-bold text-primary"
                      >Intervalo</label
                    >
                  </div>
                  <div class="col-lg-5">
                    <div class="row">
                      <span class="text-primary h5 d-lg-none d-xl-block"
                        >Turno 2</span
                      >
                      <day-schedule-input
                        :day="day"
                        :time="day.times[1]"
                        :required="false"
                        @update-start-hour="day.times[1].start_hour = $event"
                        @update-end-hour="day.times[1].end_hour = $event"
                        @update-minutes="handleHours(day)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p class="text-primary my-3 text-start fs-5">
                Horas totais:
                <b
                  >{{ hours }} horas
                  {{ minutes ? `e ${minutes} minutos` : "" }}</b
                >
              </p>
              <span class="text-center mt-4">
                <button
                  @click="handleStep(1)"
                  class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                >
                  <span class="mx-4">Continuar</span>
                </button>
              </span>
            </div>
          </div>
        </div>
        <!-- Step 7 -->
        <div class="card p-4 mt-4" v-if="actual_step === 85">
          <div
            class="progress mb-4"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="progress-bar"
              :style="'width: ' + actual_step + '%'"
            ></div>
          </div>
          <span class="text-primary h4 d-flex align-items-center">
            <span v-tooltip="'Voltar ao passo anterior'">
              <button
                @click="handleStep(0)"
                class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
              >
                <i class="fa fa-arrow-left"></i>
              </button>
            </span>
            <span>
              <span class="text-primary h4"
                >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
                <span class="small">- *Campos obrigatórios</span></span
              >
            </span>
          </span>
          <br />
          <div class="p-2">
            <h3 class="text-primary text-start">Endereço</h3>
            <div class="d-flex flex-row mb-3 align-items-start">
              <div class="row mt-4 text-start">
                <label for="input-cep p-0" class="form-label"
                  >Qual o CEP?</label
                >
                <input
                  type="text"
                  id="input-cep"
                  class="form-control mb-3 ms-3"
                  aria-describedby="input-cep"
                  placeholder="_ _ _ _ _ - _ _ _"
                  v-model="model.andress.postal_code"
                />
              </div>
              <div class="ps-4 pt-2 mt-4 ms-4">
                <button
                  @click="getCep()"
                  class="btn font-weight-bold btn-primary text-white btn-shadow mt-4"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>
            </div>
            <div class="row text-start">
              <hr class="my-4 text-gray" />
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-rua" class="form-label">Qual a rua?</label>
                <input
                  type="text"
                  id="input-rua"
                  class="form-control"
                  aria-describedby="input-rua"
                  placeholder="Informe aqui a rua"
                  v-model="model.andress.street"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary">
                <label for="input-bairro" class="form-label"
                  >Qual o bairro?</label
                >
                <input
                  type="text"
                  id="input-bairro"
                  class="form-control"
                  aria-describedby="input-bairro"
                  placeholder="Informe aqui o bairro"
                  v-model="model.andress.neighborhood"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cidade" class="form-label"
                  >Qual a cidade?</label
                >
                <input
                  type="text"
                  id="input-cidade"
                  class="form-control"
                  aria-describedby="input-cidade"
                  placeholder="Informe aqui a cidade"
                  v-model="model.andress.city"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-estado" class="form-label"
                  >Qual o estado?</label
                >
                <base-select
                  :options="ufs"
                  title="estado/uf"
                  :show_title="false"
                  @update-selected="model.uf = $event"
                  aria-describedby="input-uf"
                  id="input-uf"
                  v-model="model.andress.uf"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-nro" class="form-label"
                  >Qual o número?<span
                    v-tooltip="'Informe apenas o número, sem letras'"
                    ><i class="fas fa-info-circle ms-2"></i> </span
                ></label>
                <input
                  type="text"
                  id="input-nro"
                  class="form-control"
                  aria-describedby="input-nro"
                  placeholder="Informe aqui a nro"
                  v-model="model.andress.number"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-complemento" class="form-label"
                  >Complemento</label
                >
                <input
                  type="text"
                  id="input-complemento"
                  class="form-control"
                  aria-describedby="input-complemento"
                  placeholder="Se houver, informe o complemento"
                  v-model="model.andress.complement"
                />
              </div>
            </div>
            <span class="text-center mt-4">
              <button
                @click="handleStep(1, true)"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Finalizar</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
import BaseSelect from "@/components/BaseSelect.vue";
import DayScheduleInput from "@/components/DayScheduleInput.vue";
const toast = useToast();
export default {
  name: "employees-form",
  components: {
    BaseSelect,
    DayScheduleInput,
  },
  data() {
    return {
      ctps_type: 0,
      actual_step: 0,
      loaderActive: false,
      scholarships: [
        "Ens Fundamental Completo",
        "Ens Fundamental Incompleto",
        "Ens Médio Completo",
        "Ens Médio Incompleto",
        "Ens Superior Completo",
        "Ens Superior Incompleto",
      ],
      etnias: [
        "Pardo(a)",
        "Branco(a)",
        "Negro(a)",
        "Indígena(a)",
        "Amarelo(a)",
        "Prefiro não informar",
      ],
      genders: ["Feminino", "Masculino", "Outro"],
      status_civiles: [
        "Solteiro(a)",
        "Casado(a)",
        "Viúvo(a)",
        "Separado(a)",
        "Divorciado(a)",
      ],
      contracts: [
        "Não é contrato de expência",
        "30 dias",
        "45 dias + 45 dias",
        "60 dias",
        "90 dias",
        "30 dias + 60 dias",
        "60 dias + 30 dias",
        "45 dias",
      ],
      work_schedule: "",
      week_days: ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"],
      model: {
        mother_name: "",
        fullname: "",
        father_name: "",
        rg_number: "",
        rg_emitter: "",
        rg_date: "",
        rg_uf: "",
        cpf: "",
        status_civil: "",
        gender: "",
        date_birth: "",
        voter_section: "",
        voter_zone: "",
        voter_number: "",
        ctps_number: "",
        ctps_serie: "",
        ctps_uf: "",
        pis_number: "",
        email: "",
        phone: "",
        company_function: "",
        salary: "",
        schooling: "",
        ethnicity: "",
        city_birth: "",
        have_digital_ctps: false,
        dont_have_ctps: false,
        contract_experience_period: "",
        andress: {
          postal_code: "",
          city: "",
          uf: "",
          neighborhood: "",
          complement: "",
          number: "",
        },
      },
      steps: {
        0: "Parte 1",
        17: "Parte 2",
        34: "Parte 3",
        51: "Parte 4",
        68: "Parte 5",
        85: "Parte 6",
      },
      hours: 0,
      minutes: 0,
      ufs: [],
    };
  },
  async mounted() {
    //this.handleWorkSchedule(1);
    await this.loadUFs();
    await this.loadEmployee();
    this.handleSchedule(this.model.schedule);
  },
  computed: {
    formatedAmount() {
      if (!this.model.salary) return "";
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      })
        .format(this.model.salary / 100)
        .replace("R$", "")
        .trim();
    },
  },
  methods: {
    formatDate(date) {
      if (date) {
        const newDate = new Date(date);
        const year = newDate.getUTCFullYear();
        let day = newDate.getUTCDate();
        let month = newDate.getUTCMonth() + 1;
        if (month < "10") {
          month = "0" + month;
        }
        if (day < "10") {
          day = "0" + day;
        }
        return `${year}-${month}-${day}`;
      } else {
        return null;
      }
    },
    formatPrice(event) {
      const value = event.target.value.replace(/[^\d]/g, "");
      this.model.salary = parseInt(value || "0", 10);
      event.target.value = this.formatedAmount;
      setTimeout(() => {
        event.target.setSelectionRange(
          event.target.value.length,
          event.target.value.length
        );
      });
    },
    updateAmount() {
      var options = { style: "currency", currency: "BRL" };
      var formatter = new Intl.NumberFormat("pt-BR", options);
      var result = formatter.format(this.model.salary);
      this.amount_preview = result;
      return result;
    },
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    handleHours(day) {
      let minutes = 0;
      for (let time of day.times) {
        if (time.start_hour && time.end_hour) {
          const s_hour = parseInt(time.start_hour.split(":")[0]);
          const e_hour = parseInt(time.end_hour.split(":")[0]);
          const s_min = parseInt(time.start_hour.split(":")[1]);
          const e_min = parseInt(time.end_hour.split(":")[1]);
          let total_h = 0;
          let total_min = 0;

          if (s_hour > e_hour && e_min > s_min) {
            total_min = 60 - s_min + e_min;
            total_h = 24 - s_hour + e_hour - 1 + Math.trunc(total_min / 60);
            total_min = ((total_min % 60) / 60) * 60;
          } else if (s_hour > e_hour && e_min < s_min) {
            total_min = 60 - s_min + e_min;
            total_h = 24 - s_hour + e_hour - 1 + Math.trunc(total_min / 60);
            total_min = ((total_min % 60) / 60) * 60;
          } else if (s_hour > e_hour && e_min === s_min) {
            total_min = 0;
            total_h = 24 - s_hour + e_hour;
          } else if (s_hour === e_hour && e_min < s_min) {
            total_min = 60 - s_min + e_min;
            total_h = 24 - s_hour + e_hour - 1 + Math.trunc(total_min / 60);
            total_min = ((total_min % 60) / 60) * 60;
          } else if (s_hour === e_hour && e_min > s_min) {
            total_h = 0;
            total_min = e_min - s_min;
          } else if (s_hour === e_hour && e_min === s_min) {
            total_h = 0;
            total_min = 0;
          } else if (s_hour < e_hour && e_min < s_min) {
            total_h = e_hour - s_hour - 1;
            total_min = 60 - s_min + e_min;
          } else if (s_hour < e_hour && e_min > s_min) {
            total_h = e_hour - s_hour;
            total_min = e_min - s_min;
          } else if (s_hour < e_hour && e_min === s_min) {
            total_h = e_hour - s_hour;
            total_min = 0;
          }
          minutes += total_h * 60 + total_min;
        }
      }
      day.hours = Math.trunc(minutes / 60);
      day.minutes = ((minutes % 60) / 60) * 60;
      this.calculateHoursNMinutes();
    },
    handleSchedule(schedule) {
      const time_model = {
        start_hour: "",
        end_hour: "",
      };
      let week = [];
      this.week_days.forEach((day, index) => {
        week.push({
          day: day,
          checked: false,
          times: [],
          hours: 0,
          minutes: 0,
        });
        week[index].times.push(Object.assign({}, time_model));
        week[index].times.push(Object.assign({}, time_model));
      });
      this.work_schedule = week;
      if (schedule) {
        for (let item of schedule) {
          this.work_schedule[item.day].checked = true;
          this.work_schedule[item.day].times[0].start_hour = item.start1;
          this.work_schedule[item.day].times[0].end_hour = item.end1;
          this.work_schedule[item.day].times[1].start_hour = item.start2;
          this.work_schedule[item.day].times[1].end_hour = item.end2;
        }
      }
    },
    calculateHoursNMinutes() {
      let hours = 0;
      let minutes = 0;
      for (let day of this.work_schedule) {
        hours += day.hours;
        minutes += day.minutes;
      }
      this.hours = hours + Math.trunc(minutes / 60);
      this.minutes = ((minutes % 60) / 60) * 60;
    },
    handleFormatSchedule() {
      const schedule = [];
      for (let index in this.work_schedule) {
        const day = this.work_schedule[index];
        if (day.checked) {
          schedule.push({
            day: index,
            start1: day.times[0].start_hour,
            end1: day.times[0].end_hour,
            start2: day.times[1].start_hour,
            end2: day.times[1].end_hour,
          });
        }
      }
      return schedule;
    },
    async submmitForm(last_step) {
      if (this.ctps_type === 1) {
        this.model.have_digital_ctps = true;
        this.model.dont_have_ctps = false;
      } else if (this.ctps_type === 2) {
        this.model.have_digital_ctps = false;
        this.model.dont_have_ctps = true;
      } else {
        this.model.have_digital_ctps = false;
        this.model.dont_have_ctps = false;
      }
      const formated = {
        ...this.model,
      };
      formated.salary = formated.salary / 100;
      formated.contract_experience_period =
        this.contracts[this.model.contract_experience_period - 1];
      formated.date_birth = this.formatDate(formated.date_birth);
      formated.ctps_uf = this.model.ctps_uf
        ? (formated.ctps_uf = this.ufs[this.model.ctps_uf - 1])
        : "";
      formated.rg_uf = this.model.rg_uf
        ? (formated.rg_uf = this.ufs[this.model.rg_uf - 1])
        : "";
      formated.andress.uf = this.model.andress.uf
        ? (formated.uf = this.ufs[this.model.andress.uf - 1])
        : "";
      const schedule = this.handleFormatSchedule();
      try {
        this.showLoader();
        if (!this.$route.params.id) {
          const { data } = await api.post(`/company/employees`, {
            ...formated,
            schedule,
          });
          toast.success("Funcionario cadastrado com sucesso!");
          this.$router.push(`/cliente/funcionarios/novo/${data.id}`);
        } else {
          await api.put(`/company/employees/${this.$route.params.id}`, {
            ...formated,
            schedule,
            hideNotification: true,
          });
          if (last_step) {
            this.$router.push("/cliente/funcionarios");
          }
          toast.success("Dados do funcionário salvos com sucesso!");
          this.$router.push(
            `/cliente/funcionarios/novo/${this.$route.params.id}`
          );
        }
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos funcionários. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    formatLoadedEmployee(data) {
      if (!data.status_civil) data.status_civil = "";
      if (!data.gender) data.gender = "";
      if (!data.ethnicity) data.ethnicity = "";
      if (!data.schooling) data.schooling = "";
      if (!data.contract_experience_period)
        data.contract_experience_period = "";
      if (data.rg_uf) {
        data.rg_uf =
          this.ufs.findIndex((uf) => {
            return uf === data.rg_uf;
          }) + 1;
      }
      if (data.ctps_uf) {
        data.ctps_uf =
          this.ufs.findIndex((uf) => {
            return uf === data.ctps_uf;
          }) + 1;
      }
      if (data.contract_experience_period) {
        data.contract_experience_period =
          this.contracts.findIndex((uf) => {
            return uf === data.contract_experience_period;
          }) + 1;
      }
      data.salary *= 100;
      data.date_birth = this.formatDate(data.date_birth);
      data.rg_date = this.formatDate(data.rg_date);
      data.ctps_date = this.formatDate(data.ctps_date);
      if (data.andress && data.andress.uf) {
        data.andress.uf = this.ufs.findIndex((uf) => {
          return uf === data.andress.uf;
        });
        if (data.andress.uf < 0) data.andress.uf = "";
        if (typeof data.andress.uf === "number") data.andress.uf += 1;
      }
      if (!data.andress) data.andress = { ...this.andress_template };
      return data;
    },
    async loadEmployee() {
      this.showLoader();
      const employee_id = this.$route.params.id;
      if (employee_id) {
        try {
          const { data } = await api.get(`/company/employees/${employee_id}`);
          this.model = this.formatLoadedEmployee(data);
          if (this.model.have_digital_ctps && !this.model.dont_have_ctps) {
            this.ctps_type = 1;
          } else if (
            !this.model.have_digital_ctps &&
            this.model.dont_have_ctps
          ) {
            this.ctps_type === 2;
          } else {
            this.ctps_type === 0;
          }
        } catch (e) {
          let message =
            "Ocorreu um erro ao carregar as informações dos funcionários. Recarregue a página e tente novamente.";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      }
      this.showLoader();
    },
    async handleStep(type, last_step) {
      //0 = anterior; 1 = próximo
      if (type) {
        this.actual_step += 17;
      } else this.actual_step -= 17;
      await this.submmitForm(last_step);
    },
    async getCep() {
      const { data } = await api.get(
        `https://viacep.com.br/ws/${this.model.andress.postal_code}/json/`
      );
      this.model.street = data.logradouro;
      this.model.neighborhood = data.bairro;
      this.model.city = data.localidade;
      this.model.uf = data.uf;
      this.model.city_code = data.ibge;
    },
    async loadUFs() {
      try {
        const { data } = await api.get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome`
        );
        this.ufs = data.map((uf) => {
          return uf.sigla;
        });
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos estados. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
};
</script>
<style scoped>
.dom {
  border-radius: 20px 20px 0px 0px;
}
.sab {
  border-radius: 0px 0px 20px 20px;
}
.dom,
.ter,
.qui,
.sab {
  background-color: rgba(147, 147, 147, 0.059);
}
.seg,
.qua,
.sex {
  background-color: rgba(227, 227, 227, 0.059);
}
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}

.progress {
  border-radius: 20px;
}
</style>
